<template>
  <SwipeDialog
    :title="action + '洞见'"
    :context-menu-actions="contextMenuActions"
    :show-context-menu="showContextMenu"
    style="height: 100%; overflow: scroll"
    @closeFn="$router.go(-1)"
    @contextMenuFn="contextMenuFn"
  >
    <div style="padding: 10px">
      <div style="margin-bottom: 16px">
        <SchemaField
          v-for="(item, index) in fields"
          :key="index"
          v-model="formState[item.field]"
          :schema="item"
          @change="item?.onChange && item.onChange($event, formState)"
        />
      </div>
      <div>
        <InsightForm
          ref="insightForm"
          v-model="insightFormState"
          :init-questions="questions"
          :insight-topic-list="insightTopicList"
        />
      </div>
    </div>
    <BottomAction :confirm-loading="saveLoading" @save="save" />
  </SwipeDialog>
</template>

<script>
import BottomAction from './components/BottomAction.vue';
import InsightForm from '@/components/form/InsightForm.vue';
import SwipeDialog from '@/views/layout/swipeDialog';
import SchemaField from '@/components/form/SchemaField.vue';
import {createEditInsightRecord, fetchOnePhysicianMslInsights} from '@/api/kol';
import {validateFields} from './util';
import {Dialog, Toast} from 'vant';
import {getLocalStorage} from '@/utils';
import {
  fetchInsightTopics,
  deleteMslInsight,
  createEditPhysicianMslInsightQuestions,
  fetchPhysicianMslInsightQuestions,
} from '@/api/kol';
import asyncData from '@/mixins/asyncData';

export default {
  components: {BottomAction, InsightForm, SwipeDialog, SchemaField},
  data() {
    return {
      physicianOptions: [],
      insightFormState: {},
      saveLoading: false,
      formState: {
        physician_id: '',
        insight_source: '',
        insight_time: new Date(),
      },
      insightTopicList: null,
      questions: [],
      contextMenuActions: [{text: '删除'}],
    };
  },
  computed: {
    action() {
      return this.editId ? '编辑' : '新建';
    },
    editId() {
      return this.$route.query?.id;
    },
    resultFormState() {
      return {...this.formState, ...this.insightFormState};
    },
    showContextMenu() {
      return this.$route.query?.id?.length > 0;
    },
    fields() {
      return [
        {
          field: 'physician_id',
          label: '洞见客户',
          type: 'doctorSelectAll',
          options: this.physicianOptions,
          required: true,
        },
        {
          field: 'insight_source',
          label: '洞见渠道',
          required: true,
          type: 'select',
          options: ['AB会', '学术会议发言', '拜访', '文献阅读', '其他'],
        },
        {
          field: 'insight_time',
          type: 'date',
          label: '收集日期',
          required: true,
        },
      ];
    },
  },
  mounted() {
    if (this.editId) {
      this.fetchOnePhysicianMslInsights();
    } else {
      this.fetchInsightTopics();
    }
  },
  methods: {
    async fetchInsightTopics() {
      const res = await fetchInsightTopics(
        this.insightFormState.insight_question?.insight_topic_id,
      );
      this.insightTopicList = res;
      this.optionsKIT = res.map((item) => ({
        text: item.topic_name,
        id: item.insight_topic_id,
      }));
    },
    async fetchOnePhysicianMslInsights() {
      const res = await fetchOnePhysicianMslInsights(this.editId);
      const {
        physician,
        insight_question,
        physician_msl_insight__physician_msl_insight_questions,
        ...insightFormState
      } = res;
      this.questions = physician_msl_insight__physician_msl_insight_questions;
      this.insightFormState = {...insightFormState, insight_question};
      // this.formState.physician_id = physician_id;
      this.physicianOptions = [physician].map((item) => ({
        text: item.physician_name,
        id: item.physician_id,
      }));
      this.formState = {...insightFormState};
      // this.formState.insight_time = insight_time;
      this.fetchInsightTopics();
    },
    async save() {
      if (
        !(
          validateFields(this.fields, this.formState) &&
          this.$refs.insightForm.validateForm()
        )
      ) {
        return;
      }

      this.saveLoading = true;
      try {
        const res = await createEditInsightRecord({
          ...this.resultFormState,
          insight_user_id: getLocalStorage('user_id'),
          insight_user_name: getLocalStorage('user_name'),
        });

        const physician_msl_insight_id = res?.entity?.physician_msl_insight_id;
        await this.$refs.insightForm?.saveInsightQuestions(
          physician_msl_insight_id,
        );

        Toast(this.editId ? '修改成功' : '创建成功');
        this.$router.go(-1);
      } finally {
        this.saveLoading = false;
      }
    },
    contextMenuFn(action) {
      if (action === '删除') {
        Dialog.confirm({
          message: '是否删除这条记录',
          beforeClose: this.beforeClose,
        });
      }
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        this.saveLoading = true;
        if (this.editId) {
          deleteMslInsight(this.editId)
            .then((res) => {
              if (res?.value === this.editId) {
                this.$toast.success('删除成功');
                setTimeout(() => {
                  this.$router.go(-1);
                }, 100);
              } else {
                this.$toast.fail('删除失败，请重试');
              }
              this.saveLoading = false;
              done();
            })
            .catch((err) => {
              console.log(err);
              this.$toast.fail('删除失败，请重试');
              this.saveLoading = false;
              done();
            });
        } else {
          this.$toast.fail('删除失败，请重试');
          this.saveLoading = false;
          done();
        }
      } else if (action === 'cancel') {
        done();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
