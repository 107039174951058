<template>
  <div>
    <SchemaField
      v-for="(item, index) in insightField"
      :key="index"
      v-model="formState[item.field]"
      :schema="item"
      @change="
        item?.onChange
          ? item?.onChange($event, formState)
          : handleChangeField(item.field, $event)
      "
    />
    <SchemaField v-model="questions" :schema="questionsfieldSchema" />
    <div>
      <SchemaField
        v-if="KIQLabel.length"
        :schema="{label: 'KIQ标签'}"
        :value-border="false"
      >
        <div>
          <van-button
            v-for="(item, index) in KIQLabel"
            :key="index"
            type="primary"
            size="mini"
            round
            style="padding: 0 14px"
          >{{ item }}</van-button>
        </div>
      </SchemaField>
    </div>
    <SchemaField :schema="{label: '反馈内容'}">
      <div
        ref="feedbackContentRef"
        style="width: 100%; min-height: 100px"
        contenteditable
        @input="updateContent"
        @compositionstart="inComposition = true"
        @compositionend="
          inComposition = false;
          updateContent($event);
        "
        v-html="feedbackContentHighlight"
      ></div>
    </SchemaField>
    <SpeechToText @translateResult="feedbackContent += $event" />
  </div>
</template>

<script>
import Vue from 'vue';
import {Tab, Tabs, Picker, Popup, Icon, Button} from 'vant';
import SchemaField from './SchemaField.vue';
import {fetchInsightQuestionLabels} from '@/api/kol';
import kvpKeyMixin from '@/mixins/kvpKey';
import SpeechToText from '@/components/SpeechToText.vue';
import {flatten} from 'lodash';
import {getLocalStorage} from '@/utils/index';
import {
  createEditPhysicianMslInsightQuestions,
  deletePhysicianMslInsightQuestions,
} from '@/api/kol';
import {validateFields} from '@/views/kol/util';

Vue.use(Popup);
Vue.use(Picker);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Button);

export default {
  components: {SchemaField, SpeechToText},
  mixins: [kvpKeyMixin('product_or_disease'), kvpKeyMixin('ta')],
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    insightTopicList: Array,
    initQuestions: Array,
  },

  data() {
    return {
      formState: {
        // insight_question_id: '',
        insight_topic_id: '',
        product: '',
        product_id: '',
      },
      feedbackContent: '',
      KIQLabel: [],
      questions: [],
      products: [],
    };
  },
  computed: {
    KITKIQList() {
      const list = flatten(
        this.insightTopicList?.map((item) => {
          return item?.insight_topic__insight_questions.map((questionItem) => ({
            insight_dimension_id: item.insight_dimension?.insight_dimension_id,
            dimension_name: item.insight_dimension?.dimension_name,
            topic_name: item.topic_name,
            insight_topic_id: item.insight_topic_id,
            question: questionItem.question,
            insight_question_id: questionItem.insight_question_id,
          }));
        }),
      );

      list.push(
        ...this.initQuestions.map(
          ({physician_msl_insight_question_id, ...item}) => item,
        ),
      );

      return list;
    },
    KITKIQOptions() {
      const insightTopicList = this.insightTopicList?.map((item) => ({
        text: item.topic_name,
        id: item.insight_topic_id,
        children: item.insight_topic__insight_questions.map((item) => ({
          text: item.question,
          id: item.insight_question_id,
        })),
      }));

      console.log(insightTopicList, this.initQuestions, 'call');
      // 选项中不存在值，添加到选项中, 保证可以显示
      insightTopicList &&
        this.initQuestions?.forEach((item) => {
          const findTopic = insightTopicList.find((tropicItem) => {
            return tropicItem.id === item.insight_topic_id;
          });
          if (findTopic) {
            const findQuestion = findTopic.children.find((questionItem) => {
              return questionItem.id === item.insight_question_id;
            });
            if (!findQuestion) {
              findTopic.children.push({
                id: item.insight_question_id,
                text: item.question,
              });
            }
          } else {
            insightTopicList.push({
              id: item.insight_topic_id,
              text: item.topic_name,
              children: [
                {
                  id: item.insight_question_id,
                  text: item.question,
                },
              ],
            });
          }
        });

      return insightTopicList;
    },
    optionsKIT() {
      return this.insightTopicList?.map((item) => ({
        text: item.topic_name,
        id: item.insight_topic_id,
      }));
    },
    optionsKIQ() {
      return this.insightTopicList
        ?.find(
          (item) => item.insight_topic_id === this.formState.insight_topic_id,
        )
        ?.insight_topic__insight_questions?.map((item) => ({
          text: item.question,
          id: item.insight_question_id,
        }));
    },
    resultFormState() {
      return {
        insight_question_id: this.formState.insight_question_id,
        insight_content: this.feedbackContent,
        product: this.formState.product,
        product_id: this.formState.product_id,
        ta: this.formState.ta,
        ta_id: this.formState.ta_id,
      };
    },
    questionsfieldSchema() {
      return {
        field: 'questions',
        type: 'treeSelect',
        label: 'KIT/KIQ',
        onChange: (e, form) => {},
        options: this.KITKIQOptions,
      };
    },
    insightField() {
      return [
        {
          field: 'product',
          type: 'select',
          label: '产品或疾病',
          onChange: (e, form) => {
            const target = this.products.find(
              (item) => item.kvp_key_name === e,
            );
            form.product_id = target.kvp_key_id;
            form.ta_id = target.parent_kvp_key_id;
            form.ta = this.ta.find((item) => item.kvp_key_id === form.ta_id)?.kvp_key_name;
            console.log('ta', form.ta_id, form.ta);
          },
          options: this.products.map((item) => item.kvp_key_name),
          required: true,
        },
        // {
        //   field: 'insight_topic_id',
        //   label: 'KIT',
        //   type: 'select',
        //   options: this.optionsKIT,
        // },
        // {
        //   field: 'insight_question_id',
        //   label: 'KIQ',
        //   type: 'select',
        //   options: this.optionsKIQ,
        // },
      ];
    },
    feedbackContentHighlight() {
      let result = this.feedbackContent;
      this.KIQLabel.forEach((item) => {
        if (!result) return;
        result = result.replaceAll(
          item,
          `<span class="highlight">${item}</span>`,
        );
      });
      return result;
    },
  },
  watch: {
    initQuestions: {
      immediate: true,
      handler(val) {
        this.questions = val?.map((item) => item.insight_question_id);
      },
    },
    'formState.insight_question_id'(val) {
      console.log(val, 'change formstate');
      if (!val) return;
      this.fetchInsightQuestionLabels(val);
    },
    resultFormState(newValue, oldValue) {
      this.$emit('input', newValue);
    },
    product_or_disease(val) {
      const tas = getLocalStorage('tas');
      if (tas) {
        const taList = tas.split(',');
        this.products = val.filter(
          (item) => taList.indexOf(item.parent_kvp_key_id) >= 0,
        );
      }
      console.log('product_or_disease', val, this.products);
    },
    value: {
      handler(val) {
        this.feedbackContent = val.insight_content;

        try {
          this.formState.product = val.product;
          this.formState.product_id = val.product_id;
          this.formState.insight_question_id =
            val.insight_question.insight_question_id || val.insight_question_id;
          this.formState.insight_topic_id =
            val.insight_question.insight_topic_id;
          this.formState.ta = val.ta;
          this.formState.ta_id = val.ta_id;
        } catch (err) {
          return;
        }
      },
      immediate: true,
    },
  },
  // 处理中文输入,
  inComposition: false,

  methods: {
    async fetchInsightQuestionLabels(id) {
      const res = await fetchInsightQuestionLabels(id);
      this.KIQLabel = res.map((item) => item.label_name);
    },

    updateContent(e) {
      if (this.inComposition) {
        return;
      }
      const input = this.$refs.feedbackContentRef;
      var position = getCaretCharacterOffsetWithin(input);
      console.log(position, 'position');
      this.feedbackContent = e.target.innerText;
      this.$nextTick(() => {
        setCaretPosition(input, position);
      });
    },
    handleChangeField(field, value) {
      if (field === 'insight_topic_id') {
        this.$nextTick(() => {
          this.formState.insight_question_id = this.optionsKIQ[0].id;
        });
      }
      console.log(field, value, 'field change');
    },
    async saveInsightQuestions(physician_msl_insight_id) {
      // 删除所有旧的关系
      if (this.initQuestions?.length) {
        await deletePhysicianMslInsightQuestions(
          this.initQuestions?.map(
            (item) => item.physician_msl_insight_question_id,
          ),
        );
      }
      const saveList =
        this.questions?.map((questionId) =>
          this.KITKIQList.find(
            (item) => questionId === item.insight_question_id,
          ),
        ) || [];

      // 添加所有新的关系
      await Promise.all(
        saveList?.map((item) =>
          createEditPhysicianMslInsightQuestions({
            physician_msl_insight_id,
            ...item,
          }),
        ),
      );
    },
    validateForm() {
      return validateFields(this.insightField, this.formState);
    },
  },
};

function getCaretCharacterOffsetWithin(element) {
  var caretOffset = 0;
  var doc = element.ownerDocument || element.document;
  var win = doc.defaultView || doc.parentWindow;
  var sel;
  if (typeof win.getSelection !== 'undefined') {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      var range = win.getSelection().getRangeAt(0);
      var preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type !== 'Control') {
    var textRange = sel.createRange();
    var preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint('EndToEnd', textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}

function setCaretPosition(element, offset) {
  var range = document.createRange();
  var sel = window.getSelection();

  // select appropriate node
  var currentNode = null;
  var previousNode = null;

  for (var i = 0; i < element.childNodes.length; i++) {
    // save previous node
    previousNode = currentNode;

    // get current node
    currentNode = element.childNodes[i];
    // if we get span or something else then we should get child node
    while (currentNode.childNodes.length > 0) {
      currentNode = currentNode.childNodes[0];
    }

    // calc offset in current node
    if (previousNode != null) {
      offset -= previousNode.length;
    }
    // check whether current node has enough length
    if (offset <= currentNode.length) {
      break;
    }
  }
  // move caret to specified offset
  if (currentNode != null) {
    range.setStart(currentNode, offset);
    range.collapse(true);
    sel.removeAllRanges();
    sel.addRange(range);
  }
}
</script>

<style lang="scss" scoped>
::v-deep .highlight {
  background-color: #24e57e;
}

::v-deep .display-item {
  background-color: #d8e4fc;
  margin-top: 0.16rem;
  border-radius: 0.106667rem !important;
}

::v-deep .display-container {
  margin-top: -0.16rem;
}

::v-deep .van-field__label {
  width: auto;
}
</style>
