<template>
  <SwipeDialog
    :title="title"
    :context-menu-actions="contextMenuActions"
    :show-context-menu="showContextMenu"
    style="height: 100%; overflow: scroll"
    @closeFn="$router.go(-1)"
    @contextMenuFn="contextMenuFn"
  >
    <van-tabs
      v-model="tabIndex"
      color="#1989fa"
      style="position: sticky; top: 0px; z-index: 9"
    >
      <van-tab
        v-for="item in [name + '信息', '医学洞见']"
        :key="item"
        :title="item"
      />
    </van-tabs>
    <div style="padding: 10px">
      <div v-show="tabIndex === 0">
        <div v-for="(item, index) in fields" :key="index">
          <SchemaField
            v-if="item?.field"
            v-model="formState[item.field]"
            :schema="item"
          />
          <SchemaField v-else-if="item?.type === 'space'" :schema="item" />
        </div>
        <!-- <SuggestInsightList @addClick="handleAddSuggestion($event)" /> -->
      </div>

      <div v-show="tabIndex === 1">
        <InsightEditList
          ref="insightEditList"
          :physician_id="physician?.physician_id"
          :source_msl_call_id="editId"
        />
      </div>

      <BottomAction :confirm-loading="saveLoading" @save="save" />
    </div>
  </SwipeDialog>
</template>

<script>
import Vue from 'vue';
import {Dialog, Tab, Tabs, Picker, Popup, Icon, Button, Toast} from 'vant';
import SwipeDialog from '@/views/layout/swipeDialog';
import SchemaField from '@/components/form/SchemaField.vue';
import BottomAction from './components/BottomAction.vue';
import {createVisitRecord} from '@/api/kol';
import {validateFields} from './util';
import {
  fetchOnePhysicianMslCalls,
  createMslCallCompanion,
  deleteMslCallCompanion,
  deleteMslCall,
} from '@/api/kol';
import RoundButton from './components/RoundButton.vue';
import InsightEditList from './components/InsightEditList.vue';
// import SuggestInsightList from './components/SuggestInsightList.vue';
import kvpKeysMixin from '@/mixins/kvpKey';
import {getLocalStorage} from '@/utils';
import {fetchVUsers} from '../../api/kol';

Vue.use(Popup);
Vue.use(Picker);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(Button);

export default {
  components: {
    // SuggestInsightList,
    SwipeDialog,
    SchemaField,
    BottomAction,
    InsightEditList,
  },
  mixins: [
    kvpKeysMixin('product_or_disease'),
    kvpKeysMixin('call_channel'),
    kvpKeysMixin('msl_call_purpose')
  ],
  data() {
    return {
      saveLoading: false,
      tabIndex: 0,
      formState: {
        call_time: new Date(),
        physician_msl_call__physician_msl_call_companions: [],
      },
      test: '',
      physicianOptions: [],
      physician: {},
      old_physician_msl_call__physician_msl_call_companions: [],
      contextMenuActions: [
        { text: '删除' },
      ],
      products: [],
    };
  },
  computed: {
    is_co_call() {
      return this.isCoVisit ? 1 : 0;
    },
    name() {
      return this.isCoVisit ? '协访' : '拜访';
    },
    title() {
      const action = this.editId ? '编辑' : '新建';
      return action + this.name;
    },
    isCoVisit() {
      return this.$route.query.isCoVisit === '1';
    },
    editId() {
      return this.$route.query?.id;
    },
    fields() {
      return this.isCoVisit ? this.coVisitFields : this.visitFields;
    },
    showContextMenu() {
      return this.$route.query?.id?.length > 0;
    },
    coVisitFields() {
      return [
        {
          field: 'physician_id',
          label: '协访客户',
          required: true,
          type: 'doctorSelectAll',
          options: this.physicianOptions,
        },
        {
          field: 'physician_msl_call__physician_msl_call_companions',
          label: '协访同事',
          type: 'userSelect',
          props: {
            fn: fetchVUsers,
          },
        },
        {
          field: 'call_time',
          type: 'date',
          label: '协访日期',
          required: true,
        },
        {
          type: 'space',
        },
        {
          field: 'call_channel',
          label: '协访形式',
          type: 'select',
          options: this.call_channel?.map((item) => item.kvp_key_name),
        },
        {
          field: 'call_product',
          type: 'select',
          label: '产品或疾病',
          options: this.products.map((item) => item.kvp_key_name),
          onChange: (e, form) => {
            const target = this.products.find(
              (item) => item.kvp_key_name === e,
            );
            form.call_product_id = target.kvp_key_id;
          },
          required: true,
        },
        {
          field: 'call_description',
          label: '协访说明',
          type: 'textarea',
          placeholder: '协访目的或协访结果说明',
        },
      ];
    },
    visitFields() {
      return [
        {
          field: 'physician_id',
          label: '拜访客户',
          required: true,
          type: 'doctorSelectAll',
          options: this.physicianOptions,
        },
        // {
        //   field: 'call_location',
        //   label: '拜访地点',
        //   required: true,
        //   type: 'select',
        //   options: ['院内'],
        // },
        {
          field: 'call_time',
          type: 'date',
          label: '拜访日期',
          required: true,
        },
        {
          field: 'call_channel',
          label: '拜访形式',
          type: 'select',
          options: this.call_channel.map((item) => item.kvp_key_name),
        },
        {
          type: 'space',
        },
        {
          field: 'call_purpose',
          label: '拜访目标',
          type: 'multiSelect',
          options: this.msl_call_purpose.map((item) => ({
            label: item.kvp_key_name,
            value: item.kvp_key_name,
          })),
          formatToValue(value) {
            return value?.join(',');
          },
          formatFromValue(value) {
            return value?.split(',');
          }
        },
        {
          field: 'physician_msl_call__physician_msl_call_companions',
          label: '医学部同行人',
          type: 'userSelect',
        },
        {
          field: 'call_product',
          type: 'select',
          label: '产品或疾病',
          options: this.products.map((item) => item.kvp_key_name),
          onChange: (e, form) => {
            const target = this.products.find(
              (item) => item.kvp_key_name === e,
            );
            form.call_product_id = target.kvp_key_id;
          },
          required: true,
        },
        {
          field: 'call_description',
          label: '拜访说明',
          type: 'textarea',
          placeholder: '拜访目的或拜访结果说明',
        },
      ];
    },
  },
  watch: {
    product_or_disease(val) {
      const tas = getLocalStorage('tas');
      if (tas) {
        const taList = tas.split(',');
        this.products = val.filter(
          (item) => taList.indexOf(item.parent_kvp_key_id) >= 0,
        );
      }
      console.log('product_or_disease', val, this.products);
    },
  },
  mounted() {
    if (this.editId) {
      this.fetchOnePhysicianMslCalls();
    }

    const targetPhysicianId = this.$route.query?.physician_id;
    if (targetPhysicianId) {
      this.formState.physician_id = targetPhysicianId;
    }
  },
  methods: {
    handleChange(field, value) {
      console.log(field, value, 'field value');
      this.formState[field] = value;
    },
    async fetchOnePhysicianMslCalls() {
      const {physician, ...res} = await fetchOnePhysicianMslCalls(this.editId);
      this.old_physician_msl_call__physician_msl_call_companions = [
        ...res.physician_msl_call__physician_msl_call_companions,
      ];
      this.physician = physician;
      this.formState = res;
    },

    async save() {
      const {
        physician_msl_call__physician_msl_call_companions,
        physician,
        ...formState
      } = this.formState;

      if (!validateFields(this.fields, formState)) return;
      this.saveLoading = true;
      try {
        const res = await createVisitRecord({
          ...formState,
          is_co_call: this.is_co_call,
          call_msl_user_id: getLocalStorage('user_id'),
          call_msl_user_name: getLocalStorage('user_name'),
        });
        const source_msl_call_id = res?.entity?.physician_msl_call_id;

        // 创建/编辑对应的医学洞见
        await this.$refs.insightEditList?.save?.(
          source_msl_call_id,
          this.isCoVisit ? '协访' : '拜访',
          this.formState.physician_id
        );

        await Promise.all(
          this.old_physician_msl_call__physician_msl_call_companions
            .filter(
              (oldItem) =>
                // 旧的同行人在新的同行人列表中，则不删除
                !physician_msl_call__physician_msl_call_companions.find(
                  (newItem) => newItem.user_id === oldItem.user_id,
                ),
            )
            .map((item) =>
              deleteMslCallCompanion(item.physician_msl_call_companion_id),
            ),
        );

        // 关联同行人
        await Promise.all(
          physician_msl_call__physician_msl_call_companions
            .filter(
              (newItem) =>
                // 新的同行人在旧的同行人列表中，则不创建
                !this.old_physician_msl_call__physician_msl_call_companions.find(
                  (oldItem) => newItem.user_id === oldItem.user_id,
                ),
            )
            .map((item) =>
              createMslCallCompanion({
                physician_msl_call_id: source_msl_call_id,
                companion_type: '医学部同行人',
                user_id: item.user_id,
                user_name: item.user_name,
              }),
            ),
        );
        Toast(this.editId ? '修改成功' : '创建成功');
        this.$router.go(-1);
      } finally {
        this.saveLoading = false;
      }
    },
    handleAddSuggestion(item) {
      this.tabIndex = 1;
      this.$refs.insightEditList.addInsightForm({
        insight_question: item,
      });
    },
    contextMenuFn(action) {
      if (action === '删除') {
        Dialog.confirm({
          message: "是否删除这条记录",
          beforeClose: this.beforeClose,
        });
      }
    },
    beforeClose(action, done) {
      if (action === "confirm") {
        this.saveLoading = true;
        if (this.editId) {
          deleteMslCall(this.editId).then((res) => {
            if (res?.value === this.editId) {
              this.$toast.success("删除成功");
              setTimeout(() => {
                this.$router.go(-1);
              }, 100);
            } else {
              this.$toast.fail("删除失败，请重试");
            }
            this.saveLoading = false;
            done();
          }).catch((err) => {
            console.log(err);
            this.$toast.fail("删除失败，请重试");
            this.saveLoading = false;
            done();
          });
        } else {
          this.$toast.fail("删除失败，请重试");
          this.saveLoading = false;
          done();
        }
      } else if (action === 'cancel') {
        done();
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .van-tab--active {
  color: #1989fa;
}
</style>
